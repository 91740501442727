import parse from 'html-react-parser';
import swtermos from '../switch.js';

const {versao, url, base} = require('../versao.js');
const serverUrlbase = url
const serverUrl = serverUrlbase + '/api/'



export default class WS {

    async setReset(email, nome, telefone, app) {
      let result = await this.fetch3('go-secure/resetaccouny', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-central-id': 0
        },
        body: JSON.stringify({
          email: email,
          nome: nome, 
          telefone: telefone, 
          app: app
        })
      },18);
      return await result.json();
    }

    async gettermo(id, tipo) {
        let result = await this.fetch2('termos/' + id + "?tipo=" + tipo, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-central-id': 0
          }
        },18);
        return await result.json();
      }

      favicon(id) {
      }
      
      getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) === ' ') c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
      }

      async fetch2(extension, object = { headers: {} }, central = null) {
        let token = this.getCookie('token');
        let centralID = this.getCookie('central-id');
        if (centralID && centralID.indexOf(",") > -1) { centralID = centralID.split(",")[0]; this.setCookie('central-id', centralID, 1); }
        if (central) {
          centralID = central !== null ? central : centralID;
        }
        object.headers = { ...object.headers, 'x-access-token': token, 'x-central-id': centralID };
        let result = await fetch(serverUrl + extension, object);
        return result;
      }

      async fetch3(extension, object = { headers: {} }, central = null) {
        let token = this.getCookie('token');
        let centralID = this.getCookie('central-id');
        if (centralID && centralID.indexOf(",") > -1) { centralID = centralID.split(",")[0]; this.setCookie('central-id', centralID, 1); }
        if (central) {
          centralID = central !== null ? central : centralID;
        }
        object.headers = { ...object.headers, 'x-access-token': token, 'x-central-id': centralID };
        let result = await fetch(serverUrlbase + '/' + extension, object);
        return result;
      }

      async gettermos(tipotermo, tipo) {
        return await swtermos.termos(tipotermo, tipo)
      }


      title(item) {
        return swtermos.titulo(item)
      }
      
      seo(data = {}) {
        data.title = data.title || 'Default title';
        data.metaDescription = data.metaDescription || 'Default description';
        document.title = data.title;
        document.querySelector('meta[name="description"]').setAttribute('content', data.metaDescription);
      }
      
      renderData(itens) {
        let saida = []
        let links = []
        let buffer = ""
        for (let index = 0; index < itens.length; index++) {
          const a = itens[index];
          if (a.tipo === 1) {
            saida.push(<div key={'key' + index}><p style={{textAlign: 'justify', paddingBottom: 13}}>{parse(a.txt)}</p></div>)
          } else if (a.tipo === 2) {
            saida.push(<div key={'key' + index}><h4 style={{textAlign: 'justify', paddingBottom: 13}}>{parse(a.txt)}</h4></div>)
          } else if (a.tipo === 3) {
            links.push(<li><a href={'#id' + index}>{parse(a.txt)}</a></li>)
            saida.push(<div key={'key' + index}><h3 style={{textAlign: 'justify', paddingBottom: 13}}><a id={'id' + index}></a>{parse(a.txt)}</h3></div>)
          } else if (a.tipo === 4) {
            links.push(<li><a href={'#id' + index}>{parse(a.txt)}</a></li>)
            saida.push(<div key={'key' + index}><h2 style={{textAlign: 'justify', paddingBottom: 13}}><a id={'id' + index}></a>{parse(a.txt)}</h2></div>)
          } else if (a.tipo === 5) {
            buffer = <Column flex={'2'} style={{padding:5, margin: 5, border: '1px solid black' }}><span style={{textAlign: 'justify'}}>{parse(a.txt)}</span></Column>
          } else if (a.tipo === 6) {
            saida.push(<div key={'key' + index}><Row vertical='start' horizontal='space-evenly'>{buffer}<Column flex={'3'} style={{padding:5, margin: 5, border: '1px solid black' }} ><span style={{textAlign: 'justify'}}>{parse(a.txt)}</span></Column></Row></div>)
            buffer = ""
          } else {
            saida.push (<div key={'key' + index}><p>{a.txt}</p></div>)
          }
        }
        return {links: links, data: saida}
      }

      AppStoreLink(app) {
        return swtermos.linkloja(app)
      }
}