import React, { Component } from 'react';
import WS from '../services/webservice';
import parse from 'html-react-parser';
import { Column, Row } from "simple-flexbox";
import * as rdd from 'react-device-detect';
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import InputMask from 'react-input-mask'

import '../App.css';


class Reset extends Component {
  constructor(props) {
    super(props);
    this.state = { valor: [], links: [], id: 'logo', telefone:'', email:'', nome:'', msg: '' }
    this.termos = this.termos.bind(this);
    this.goOs = this.goOs.bind(this)
    this.handleChange = this.handleChange.bind(this);
    this.ws = new WS();
  }
  async termos(id) {
    let resultado = await this.ws.AppStoreLink(id);
    console.log('resultado', resultado)
    if (!resultado.error) {
      let ios = resultado.ios ? resultado.ios.url : null
      let android = resultado.android ? resultado.android.url : null
      this.setState({
        store: resultado,
        id: id,
        android: android,
        ios: ios
      })
    }
  }

  handleChange(event) {
    console.log(event.target.id, event.target.value)
    this.setState({
      [event.target.id]: event.target.value
    });
  }

async goOs() {
  this.ws.setReset(this.state.email, this.state.nome, this.state.telefone, this.state.id)
  this.setState({
    telefone:'', email:'', nome:'', msg: 'Voce será avisado por email do andamento do processo.'
  })
}

  componentDidMount() {
    let data = this.props.match.params.id;
    console.log('date', data)
    this.ws.seo({
      title: this.ws.title(data),
      metaDescription: this.ws.title(data),
    });
    this.ws.favicon(data)
    this.termos(data)
  }

  

  render() {
    return (
      <Row>
      <Column horizontal='center' style={{padding:5, margin: 5}} >
{this.state.id != 'logo' && <>
        <Row>
          <Column horizontal='center' style={{padding:5, margin: 5}} >
            <div className={this.state.id} alt="logo" />
            <span className='titulotermos'>{this.ws.title(this.state.id)}</span>
          </Column>
        </Row>
        <Row>
        <Column horizontal='center' style={{padding:5, margin: 5}} >
          <p>Caso deseje apagar os dados seus dados de nosso sistema e apagar a conta, preencha abaixo seus dados para realizar a requisição.</p>
          </Column></Row>
        <Row>
        <Column horizontal='center' style={{padding:5, margin: 5}} >
          <p>Após o processamento da limpesa dos dados, voce será informado por email.</p>
          </Column>
          </Row>
          {this.state.msg.length > 1 &&
                  <Row>
                  <Column horizontal='center' style={{padding:5, margin: 5}} >
                    <p style={{fontWeight: 'bold'}}>Solicitação encaminhada com sucesso, você será informado ao fim do processo.</p>
                    </Column>
                    </Row>
          }
        <Row>
          <FormGroup controlId="code" bsSize="large">
          <Row>
            <Column horizontal='center' style={{padding:5, margin: 5}} >
            <FormLabel>Email</FormLabel></Column>
            <Column horizontal='center' style={{padding:5, margin: 5}} >
            <FormControl
              autoFocus
              id='email'
              value={this.state.email}
              onChange={this.handleChange}
            /></Column>
  </Row>
  <Row>
            <Column horizontal='center' style={{padding:5, margin: 5}} >
            <FormLabel>Telefone</FormLabel></Column>
            <Column horizontal='center' style={{padding:5, margin: 5}} >
            <InputMask
            value={this.state.telefone}
            id={'telefone'}
            className='form-control'
            mask={
              /^.....9/.test(this.state.telefone)
                ? '(99) 99999-9999'
                : '(99) 9999-9999'
            }
            maskChar='_'
            onChange={this.handleChange}
          /></Column>
  </Row>
  <Row>
            <Column horizontal='center' style={{padding:5, margin: 5}} >
            <FormLabel>Nome completo</FormLabel></Column>
            <Column horizontal='center' style={{padding:5, margin: 5}} >
            <FormControl
              id='nome'
              value={this.state.nome}
              onChange={this.handleChange}
            /></Column>
  </Row>
  <Row>
            
          <Column>
          <Button
          onClick={this.goOs}
            block
          >
            Enviar
          </Button></Column>
          </Row>
          </FormGroup>
        </Row>
        <Row>
          <Column horizontal='center' style={{padding:5, margin: 5}} >
            <div className={this.state.id} alt="logo" />
          </Column>
          <Column horizontal='center' style={{padding:5, margin: 5}} >
            <span className='titulotermos'>{this.ws.title(this.state.id)}</span>
          </Column>
        </Row>
        
        </>}  

      </Column>
        </Row>
      )
  }
}

export default Reset;
