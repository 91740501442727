import swList from './swList.js';
import dados from './jsonDir/lista.json'
import titulos from './jsonDir/titulos.json'
import apps from './jsonDir/apps.json'






class swtermos {
  static itemlist() {
    return dados
  }
  
  
  
  static titulo(tipotermo) {
      let item = tipotermo.toLowerCase()
      if (titulos[item]) {
        return titulos[item]
      } else {
        return ""
      }
    }
  
  
  static termos(tipotermo, tipo) {
    let item = tipotermo.toLowerCase() 
    return swList.dados()[item]
  }
  
  
  static linkloja(app) {
    let LinkUsar = apps['default'];
    if (apps[app]) {
      LinkUsar = apps[app];
    }
    return LinkUsar
  }
}

export default swtermos
//module.exports = swtermos