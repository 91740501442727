import React, { Component } from 'react';
import '../App.css';
import logo from '../images/logo.png';
import untaxi from '../images/appc1taxi.png';
import { versao, alt, app } from '../versao.js'
import dados from '../jsonDir/lista.json'


export default class Main extends Component {


  

    constructor(props) {
      super(props);

    }

    componentDidMount() {
    }




    render() {
        return (<>
            <div className="App">
                <header className="App-header">
                    <img src={!!alt ? untaxi : logo} className="App-logo" alt="logo" />
                        <p>{app}-{versao}</p>
                    <img alt="" src={!!alt ? untaxi : logo} height={65} />
                </header>
            </div>
            <div style={{width: 400, display: "none"}}>
                {dados.map(x => {
                    return (<><a href={'privacy/' + x}>{x}</a><a href={'privacidade/' + x}>{x}</a><a href={'termos/' + x}>{x}</a></>)
                })}
            </div>
        </>)
    }
}