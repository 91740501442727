import React, { Component } from 'react';
import WS from '../services/webservice';
import parse from 'html-react-parser';
import { Column, Row } from "simple-flexbox";

import '../App.css';


class Uso extends Component {
  constructor(props) {
    super(props);
    this.state = { valor: [], links: [], id: 'logo' }
    this.termos = this.termos.bind(this);

    this.ws = new WS();
  }
  async termos(id) {
    let resultado = await this.ws.gettermos(id, 1);
    if (!resultado.error) {
      let valor = this.ws.renderData(resultado.msg)
      this.setState({
        valor: valor.data,
        links: valor.links,
        id: id
      })
    }
  }
  componentDidMount() {
    let data = this.props.match.params.id;
    this.ws.seo({
      title: this.ws.title(data),
      metaDescription: this.ws.title(data),
    });
    this.termos(data)
  }



  render() {

    return (
    <div style={{flex:1, width: '100%', flexDirection: 'row', justifyContent:'center', alignItems: 'center', alignContent: 'center'}}>
      {this.state.id != 'logo' && <>
        <Row vertical='start' horizontal='space-evenly'>
          <Column horizontal='end' style={{padding:5, margin: 5}} >
            <div className={this.state.id} alt="logo" />
          </Column>
          <Column vertical='end' flex={3} style={{padding:5, margin: 5}} >
            <span className='titulotermos'>{this.ws.title(this.state.id)}</span>
            <span className='subtitulotermos'>Termos de Uso</span>

          </Column>
        </Row>
        <Row vertical='start' horizontal='space-evenly'>
          <Column flex={3} style={{padding:5, margin: 5, border: '1px solid black' }} >
            {this.state.valor.map((a,b) => a)}
          </Column>
        </Row></>}
        {this.state.id === 'logo' && <>
        <Row vertical='start' horizontal='space-evenly'>
          <Column horizontal='end' style={{padding:5, margin: 5}} >
            <div className={this.state.id} alt="logo" />
          </Column>
          <Column vertical='end' flex={3} style={{padding:5, margin: 5}} >
            <span className='titulotermos'>Termos de Uso</span>
            <span className='subtitulotermos'>Carregando</span>

          </Column>
          </Row></>}
    </div>
    )
  }
}

export default Uso;
