import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Main from './components/Main.js';
import Lista from './components/Lista.js';
import Politica from './components/Politica.js';
import Privacidade from './components/Privacidade.js';
import Uso from './components/Uso.js';
import Store from './components/Store.js';
import Contrato from './components/Contrato.js';
import Goapp from './components/Goapp.js';
import Reset from './components/Reset.js';

function App() {
  return (<Router>
    <Switch>
      <Route exact path="/" component={(props) => (<Main {...props} />)} />
      <Route exact path="/lista" component={(props) => (<Lista {...props} />)} />
      <Route exact path={"/termos/:id"} component={(props) => (<Uso {...props}  />)} />
      <Route exact path={"/privacidade/:id"} component={(props) => (<Privacidade {...props} />)} />
      <Route exact path={"/service/:id"} component={(props) => (<Uso {...props} />)} />
      <Route exact path={"/privacy/:id"} component={(props) => (<Privacidade {...props} />)} />
      <Route exact path="/app/:id" component={(props) => (<Goapp {...props} />)} />
      <Route exact path="/store/:id" component={(props) => (<Store {...props} />)} />
      <Route exact path="/info/:id" component={(props) => (<Contrato {...props} />)} />
      <Route exact path="/reset/:id" component={(props) => (<Reset {...props} />)} />
    </Switch>  
    </Router>
  )
}
export default App;
