import React, { Component } from 'react';
import WS from '../services/webservice';
import parse from 'html-react-parser';
import { Column, Row } from "simple-flexbox";
import * as rdd from 'react-device-detect';
import '../App.css';


class Goapp extends Component {
  constructor(props) {
    super(props);
    this.state = { valor: [], links: [], id: 'logo' }
    this.termos = this.termos.bind(this);
    this.ws = new WS();
  }
  async termos(id) {
    let resultado = await this.ws.AppStoreLink(id);
    if (!resultado.error) {
      let ios = resultado.ios ? resultado.ios.url : null
      let android = resultado.android ? resultado.android.url : null
      this.setState({
        store: resultado,
        id: id,
        android: android,
        ios: ios
      })
    }
  }

  componentDidMount() {
    let data = this.props.match.params.id;
    this.ws.seo({
      title: this.ws.title(data),
      metaDescription: this.ws.title(data),
    });
    this.ws.favicon(data)
    this.termos(data)
  }

  

  render() {
    return (
      <div style={{flex:1, width: '100%', flexDirection: 'row', justifyContent:'center', alignItems: 'center', alignContent: 'center'}}>
{this.state.id != 'logo' && <>
        <Row vertical='start' horizontal='space-evenly'>
          <Column horizontal='center' style={{padding:5, margin: 5}} >
            <div className={this.state.id} alt="logo" />
            <span className='titulotermos'>{this.ws.title(this.state.id)}</span>
          </Column>
        </Row>
        <Row vertical='start' horizontal='space-evenly'>
          {this.state.ios &&
                <a href={this.state.ios}>
                  <Column horizontal='center' style={{padding:5, margin: 5}} >
                     <div className='ios' alt="logo" />
                  </Column>
                </a>
          }
          {this.state.android &&
                  <a href={this.state.android}>
                    <Column horizontal='center' style={{padding:5, margin: 5}} >
                      <div className='android' alt="logo" />
                    </Column>
                  </a>
          }
        </Row></>}  

      </div>
      )
  }
}

export default Goapp;
