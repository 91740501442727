import React, { Component } from 'react';
import WS from '../services/webservice';
import parse from 'html-react-parser';
import { Column, Row } from "simple-flexbox";
import * as rdd from 'react-device-detect';
import '../App.css';


class Store extends Component {
  constructor(props) {
    super(props);
    this.state = { valor: [], links: [], id: 'logo' }
    this.termos = this.termos.bind(this);
    this.ws = new WS();
  }
  async termos(id) {
    let resultado = await this.ws.AppStoreLink(id);
    if (!resultado.error) {
      let link = this.org(resultado)
      this.setState({
        store: resultado,
        link: link
      })
    }
  }
  org(LinkUsar) {
    let device = {
      loja: LinkUsar.website,
      ios: LinkUsar.ios ? LinkUsar.ios : LinkUsar.website,
      android: LinkUsar.android,
      usar: LinkUsar.website,
    };
    if (rdd.isIOS) {
      return device.ios.url
    } else if (rdd.isAndroid) {
      return device.android.url
    } else {
      return device.loja.url
    }
  }

  componentDidMount() {
    let data = this.props.match.params.id;
    this.ws.seo({
      title: this.ws.title(data),
      metaDescription: this.ws.title(data),
    });
    this.ws.favicon(data)
    this.termos(data)
  }

  

  render() {
    return (
      <div style={{flex:1, width: '100%', flexDirection: 'row', justifyContent:'center', alignItems: 'center', alignContent: 'center'}}>
        {this.state.link && window.location.replace(this.state.link)}
      </div>
      )
  }
}

export default Store;
