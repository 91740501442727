import dados from './jsonDir/lista.json';
import appcvaide10 from './jsonDir/appcvaide10.json';
import appcvia7 from './jsonDir/appcvia7.json';
import appcrocha from './jsonDir/appcrocha.json';
import appc1taxi from './jsonDir/appc1taxi.json';
import appcgogogo from './jsonDir/appcgogogo.json';
import appcshopper from './jsonDir/appcshopper.json';
import appcurbano from './jsonDir/appcurbano.json';
import appmurbano from './jsonDir/appmurbano.json';
import appmshopper from './jsonDir/appmshopper.json';
import appmgogogo from './jsonDir/appmgogogo.json';
import appm1taxi from './jsonDir/appm1taxi.json';
import appmvaide10 from './jsonDir/appmvaide10.json';
import appmrocha from './jsonDir/appmrocha.json';
import appmvia7 from './jsonDir/appmvia7.json';
import appcexpress75 from './jsonDir/appcexpress75.json';
import appmexpress75 from './jsonDir/appmexpress75.json';
import appcdrive45 from './jsonDir/appcdrive45.json';
import appmdrive45 from './jsonDir/appmdrive45.json';
import appcmobi from './jsonDir/appcmobi.json';
import appmmobi from './jsonDir/appmmobi.json';
import appcgoiasdel from './jsonDir/appcgoiasdel.json';
import appmgoiasdel from './jsonDir/appmgoiasdel.json';
import appcrota92 from './jsonDir/appcrota92.json';
import appmrota92 from './jsonDir/appmrota92.json';
import appcvoudeale from './jsonDir/appcvoudeale.json';
import appmvoudeale from './jsonDir/appmvoudeale.json';
import appcspeedlog from './jsonDir/appcspeedlog.json';
import appmspeedlog from './jsonDir/appmspeedlog.json';
import appcrochall from './jsonDir/appcrochall.json';
import appmrochall from './jsonDir/appmrochall.json';
import appcpopify from './jsonDir/appcpopify.json';
import appmpopify from './jsonDir/appmpopify.json';
import appcprime from './jsonDir/appcprime.json';
import appmprime from './jsonDir/appmprime.json';

export default class swList {
static dados() {
return {
  'appcvaide10': appcvaide10,
  'appcvia7': appcvia7,
  'appcrocha': appcrocha,
  'appc1taxi': appc1taxi,
  'appcgogogo': appcgogogo,
  'appcshopper': appcshopper,
  'appcurbano': appcurbano,
  'appmurbano': appmurbano,
  'appmshopper': appmshopper,
  'appmgogogo': appmgogogo,
  'appm1taxi': appm1taxi,
  'appmvaide10': appmvaide10,
  'appmrocha': appmrocha,
  'appmvia7': appmvia7,
  'appcexpress75': appcexpress75,
  'appmexpress75': appmexpress75,
  'appcdrive45': appcdrive45,
  'appmdrive45': appmdrive45,
  'appcmobi': appcmobi,
  'appmmobi': appmmobi,
  'appcgoiasdel': appcgoiasdel,
  'appmgoiasdel': appmgoiasdel,
  'appcrota92': appcrota92,
  'appmrota92': appmrota92,
  'appcvoudeale': appcvoudeale,
  'appmvoudeale': appmvoudeale,
  'appcspeedlog': appcspeedlog,
  'appmspeedlog': appmspeedlog,
  'appcrochall': appcrochall,
  'appmrochall': appmrochall,
  'appcpopify': appcpopify,
  'appmpopify': appmpopify,
  'appcprime': appcprime,
  'appmprime': appmprime,
}
}
}