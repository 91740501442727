import React, { Component } from 'react';
import swtermos from '../switch.js';

import '../App.css';

export default class Lista extends Component {
    constructor(props) {
      super(props);
      this.state = {
      }
    }

    

    render() {
        return (<>
            <div style={{width: '100%'}}>
                {swtermos.itemlist().map(x => {
                    return (
                    <div className='inline'>
                        <a href={'app/' + x}>
                        <div className={x} alt="logo" />
                        <div><span className='titulotermos2'>{swtermos.titulo(x)}</span></div>
                        </a>
                    </div>
                    )
                })}
            </div>
        </>)
    }
}